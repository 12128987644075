exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auto-index-js": () => import("./../../../src/pages/auto/index.js" /* webpackChunkName: "component---src-pages-auto-index-js" */),
  "component---src-pages-beauty-index-js": () => import("./../../../src/pages/beauty/index.js" /* webpackChunkName: "component---src-pages-beauty-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-business-index-js": () => import("./../../../src/pages/business/index.js" /* webpackChunkName: "component---src-pages-business-index-js" */),
  "component---src-pages-contact-us-file-upload-js": () => import("./../../../src/pages/contact-us/file-upload.js" /* webpackChunkName: "component---src-pages-contact-us-file-upload-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-contact-us-thanks-js": () => import("./../../../src/pages/contact-us/thanks.js" /* webpackChunkName: "component---src-pages-contact-us-thanks-js" */),
  "component---src-pages-contact-us-waitlist-thanks-js": () => import("./../../../src/pages/contact-us/waitlist-thanks.js" /* webpackChunkName: "component---src-pages-contact-us-waitlist-thanks-js" */),
  "component---src-pages-cookie-policy-index-js": () => import("./../../../src/pages/cookie-policy/index.js" /* webpackChunkName: "component---src-pages-cookie-policy-index-js" */),
  "component---src-pages-fitness-index-js": () => import("./../../../src/pages/fitness/index.js" /* webpackChunkName: "component---src-pages-fitness-index-js" */),
  "component---src-pages-food-index-js": () => import("./../../../src/pages/food/index.js" /* webpackChunkName: "component---src-pages-food-index-js" */),
  "component---src-pages-football-index-js": () => import("./../../../src/pages/football/index.js" /* webpackChunkName: "component---src-pages-football-index-js" */),
  "component---src-pages-gaming-index-js": () => import("./../../../src/pages/gaming/index.js" /* webpackChunkName: "component---src-pages-gaming-index-js" */),
  "component---src-pages-golf-index-js": () => import("./../../../src/pages/golf/index.js" /* webpackChunkName: "component---src-pages-golf-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-play-index-js": () => import("./../../../src/pages/play/index.js" /* webpackChunkName: "component---src-pages-play-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-tags-blogs-index-js": () => import("./../../../src/pages/tags/blogs/index.js" /* webpackChunkName: "component---src-pages-tags-blogs-index-js" */),
  "component---src-pages-tags-news-index-js": () => import("./../../../src/pages/tags/news/index.js" /* webpackChunkName: "component---src-pages-tags-news-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-link-js": () => import("./../../../src/templates/news-link.js" /* webpackChunkName: "component---src-templates-news-link-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

